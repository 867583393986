import { FC, useCallback } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';

import { CopyIcon } from '@21vek/admin-front-components';
import { helpers, useSnackbar } from '@21vek/admin-front-common';

import styles from './CopyIconButton.module.css';

interface CopyIconButtonProps {
  value: string;
  popupText?: string;
}

const CopyIconButton: FC<CopyIconButtonProps> = ({ value, popupText = 'Значение скопировано' }) => {
  const snackbar = useSnackbar();

  const handleCopyButtonClick = useCallback(async (e) => {
    e.stopPropagation();
    if (await helpers.copyToClipboard(value)) {
      snackbar.open(popupText);
    }
  }, [snackbar, value]);

  return (
    <Tooltip title={'Скопировать'}>
      <IconButton className={styles.copyIcon} onClick={handleCopyButtonClick}>
        <CopyIcon />
      </IconButton>
    </Tooltip>
  );
};

export default CopyIconButton;
