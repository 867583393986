import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    height: '2rem',
  },
  selected: {
    background: 'initial !important',
    cursor: 'auto',
    '&:hover': {
      background: '#0077cc14 !important',
    },
  },
  circle: {
    width: 6,
    height: 6,
    display: 'inline-block',
    borderRadius: 3,
    borderColor: theme.palette.grey['500'],
    marginLeft: 'auto',
    marginRight: 12,
    boxSizing: 'border-box',
  },
  inputLabel: {
    display: 'flex',
    alignItems: 'center',
    color: ({ contrastingLabel }) =>
      contrastingLabel ? theme.palette.text.primary : undefined,
    fontWeight: ({ contrastingLabel }) => contrastingLabel
      ? 500
      : 400,
    fontSize: ({ contrastingLabel }) => contrastingLabel && '14px',
    '& svg': {
      color: theme.palette.grey[500],
      width: ({ contrastingLabel }) => contrastingLabel ? '12px' : '14px',
      height: ({ contrastingLabel }) => contrastingLabel ? '12px' : '14px',
      margin: ({ contrastingLabel }) => contrastingLabel ? '0 1px' : '0 -3px -3px 1px',
      
      '&:hover': {
        color: theme.palette.primary.light,
      }
    },
  },
}));
